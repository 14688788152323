<template>
  <div class="mb-5">
    <b-row class="my-2">
      <b-col md="12">
        <h1 class="text-center">
          Veuillez vous assurer que les informations entrées sont correctes puis
          enregistrez le formulaire
        </h1>
      </b-col></b-row
    >
    <b-card class="preview-document">
      <!-- entete -->
      <!-- <div class="d-flex justify-content-between">
        <div class="col-4">
          <AppLogo />
        </div>
        <div class="col-4 text-right">
          O1 BP 2037 <br>
          Cotonou, Bénin <br>
          Tel : +229 21 30 70 13 <br>
          +229 21 30 70 10 <br>
          mtca.sp@gouv.bj
        </div>
      </div> -->

      <b-row>
        <b-col cols="12">
          <!-- <div class="d-flex justify-content-center text-center mb-3 mt-1">
            <div>
              <h1> FICHE D'ENQUETE SUR LE TOURISME INTERNE </h1>
            </div>
          </div>  -->
          <!-- content-->
          <div v-if="infos" class="pl-1 pr-1">
            <div class="customborder1 p-2">
              <b-row>
                <!-- pays résidence -->
                <b-col md="12" class="mb-1">
                  1. Dans quel pays vivez vous ?:
                  <strong>{{ infos.paysResidence }}</strong>
                </b-col>
                <!-- nationalite -->
                <b-col md="12" class="mb-1">
                  3. De quelle nationalité êtes-vous ? :
                  <strong>{{ infos.nationalite }}</strong>
                </b-col>

                <!-- date départ bénin -->
                <b-col md="12" class="mb-1">
                  4. A quelle date êtes-vous arrivés au Bénin ?
                  <strong>{{ infos.dateArriveeBenin }}</strong>
                </b-col>

                <!-- compte voyageurs -->
                <b-col md="12" class="mb-1">
                  6. Avec combien de personnes, y compris vous-même,
                  voyagez-vous en partageant les dépenses ?
                  <strong>{{ infos.coVoyageursAdultes }}</strong> personnes dont
                  <strong>{{ infos.coVoyageursEnfants }}</strong> enfants de
                  moins de 18 ans.
                </b-col>

                <hr />

                <!-- motif de votre visite -->
                <b-col md="12" class="mb-1">
                  7. Quel a été le motif de votre visite au Bénin ?
                  <strong>{{ infos.motifVoyage }}</strong>
                </b-col>

                <!-- activités pratiquée -->
                <b-col md="12" class="mb-1">
                  8. Parmi les activités suivantes, lesquelles avez-vous
                  pratiquées ? <br />
                  <strong
                    class="mx-1"
                    v-for="(item, index) in infos.activiteBenin"
                    :key="'activite' + index"
                    >{{ item }}</strong
                  >
                </b-col>

                <!-- nombre nuits -->
                <b-col md="12" class="mb-1">
                  9. Indiquez le nombre de nuits passées par type d’hébergement
                  pendant votre séjour au Bénin. <br />
                  <strong
                    class="mx-1"
                    v-for="(item, index) in infos.nombreNuitParType"
                    :key="index"
                  >
                    {{ item.type }} - {{ item.nombreNuit || 0 }} <br />
                  </strong>
                  <!-- <strong> {{infos.nombreNuitParType}} </strong> -->
                </b-col>

                <!-- moyen transport départ bénin -->
                <b-col md="12" class="mb-1">
                  Par quel principal moyen de transport êtes-vous arrivé au
                  Bénin ?
                  <strong> {{ infos.moyenTransportArriveeBenin }} </strong>
                </b-col>
                <!-- départ par avion -->
                <b-col
                  md="12"
                  class="mb-1"
                  v-if="
                    infos.arriveeBeninParAvion &&
                    infos.moyenTransportArriveeBenin == 'Avion'
                  "
                >
                  9. Si vous êtes arrivé par avion, veuillez préciser s’il
                  s’agit :
                  <strong> {{ infos.arriveeBeninParAvion.type }} ;</strong>
                  <strong> {{ infos.arriveeBeninParAvion.compagnie }} </strong>
                </b-col>
                <!-- départ par bus -->
                <b-col
                  md="12"
                  class="mb-1"
                  v-if="infos.moyenTransportArriveeBenin == 'Autobus'"
                >
                  10. Si vous êtes arrivé en autobus, veuillez préciser :
                  <strong>
                    {{ infos.arriveeBeninParAutobus.compagnie }}
                  </strong>
                  <strong>
                    {{ infos.arriveeBeninParAutobus.residenceCompagnie }}
                  </strong>
                </b-col>
                <!-- moyen transport utilisé a étranger -->
                <b-col md="12" class="mb-1">
                  11. Quels moyens de transport avez-vous utilisé au Bénin
                  ?<strong> {{ infos.moyenTransportBenin }} </strong>
                </b-col>
                <!-- dépenses voyage avant depart -->
                <b-col md="12" class="mb-1" v-if="infos.depensesAvantArrivee">
                  12. Indiquez combien vous avez payé en tout pour ce voyage
                  avant votre arrivée au Bénin <br />
                  <!-- {{ infos.depensesAvantArrivee.type }} -->
                  <strong>{{ infos.depensesAvantArrivee.type.name }}</strong>

                  <div
                    v-if="
                      infos.depensesAvantArrivee.type.code != 'aucun_paiement'
                    "
                  >
                    ayant coûté
                    <strong>
                      {{ infos.depensesAvantArrivee.coutTotal }}
                      {{ infos.depensesAvantArrivee.devise }}
                    </strong>
                    <br />
                    <strong
                      class="mx-1"
                      v-for="(item, index) in infos.depensesAvantArrivee
                        .options"
                      :key="index"
                    >
                      <span
                        v-if="infos.depensesAvantArrivee.type.code == 'forfait'"
                      >
                        <span v-if="item.selected == true">
                          <feather-icon icon="CheckIcon" /> {{ item.title }}
                          <br />
                        </span>
                        <span v-else> {{ item.titre }} <br /> </span>
                      </span>
                      <span
                        v-if="
                          infos.depensesAvantArrivee.type.code == 'sans_forfait'
                        "
                      >
                        <span v-if="item.selected == true">
                          <feather-icon icon="CheckIcon" /> {{ item.title }} -
                          {{ item.value }} <br />
                        </span>
                        <span v-else class="ml-1">
                          {{ item.titre }} <br />
                        </span>
                      </span>
                    </strong>
                  </div>
                  <!-- <strong v-if="infos.depensesAvantArrivee.type || infos.depensesAvantArrivee.type.code == 'aucun_paiement' ">
                    Je n'ai rien payé avant mon arrivé
                  </strong> -->
                </b-col>
                <!-- dépenses voyage durant sejour -->
                <b-col md="12" class="mb-1" v-if="infos.depensesDurantSejour">
                  13. Indiquez combien vous avez payé en tout pour ce voyage
                  pendant votre séjour au Bénin. <br />
                  <strong>{{ infos.depensesDurantSejour.type }}</strong
                  ><br />
                  <span
                    v-if="
                      infos.depensesDurantSejour.type !=
                      'Je n\'ai rien payé pendant mon séjour'
                    "
                  >
                    <strong
                      class="mx-1"
                      v-for="(item, index) in infos.depensesDurantSejour
                        .options"
                      :key="index"
                    >
                      <span
                        >{{ item.label }} - {{ item.value }} {{ item.devise
                        }}<br
                      /></span>
                    </strong>
                  </span>
                </b-col>
                <!-- satisfaction -->
                <b-col md="12" class="mb-1">
                  14. Dans l’ensemble, votre séjour au Bénin vous a-t-il
                  satisfait ? <strong> {{ infos.satisfactionSejour }} </strong>
                </b-col>
                <!-- fréquence voyage -->
                <b-col md="12" class="mb-1">
                  15. Venez-vous souvent au Bénin ? :
                  <strong> {{ infos.frequenceVoyage }} </strong>
                </b-col>
                <!-- sexe -->
                <b-col md="12" class="mb-1">
                  16. Sexe <strong> {{ infos.sexe }} </strong>
                </b-col>
                <!-- age -->
                <b-col md="12" class="mb-1">
                  17. Votre âge : <strong> {{ infos.age }} </strong> ans
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="px-1 mt-2">
        <b-col md="6">
          <div class="text-left">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="secondary"
              class="col-xl-4"
              @click="edit()"
            >
              Retourner sur le formulaire
            </b-button>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="col-xl-4"
              @click="save()"
            >
              <span v-if="isSavingForm" class="d-flex align-items-center"
                ><div class="mr-2"><b-spinner /></div>

                <span class=""> Validation du formulaire... </span>
              </span>
              <span v-if="!isSavingForm"> Enregistrer les informations </span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal backdrop -->
    <b-modal
      :ref="sectionEditionModalRef"
      scrollable
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      title="Modifier cette section"
      size="lg"
    >
      <component
        :is="sectionToEditComponent"
        :input-data="sectionToEditData"
        :show-edition-buttons="true"
        @onFormSuccess="onEditionFormSuccess($event)"
        @onCancel="hideSectionEditionModal"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BFormRadioGroup,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BForm,
  BCardText,
  VBTooltip,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";
import AppLogo from "@/components/AppLogo.vue";
import localstorageService from "@/services/localstorage/localstorage.service";

import FormIdentification from "./sections/FormIdentification.vue";
import FormClassification from "./sections/FormClassification.vue";
import FormTableau1 from "./sections/FormTableau1.vue";
import FormTableau2 from "./sections/FormTableau2.vue";
import FormTableau3 from "./sections/FormTableau3.vue";
import FormTableau4 from "./sections/FormTableau4.vue";

import utilsService from "@/services/utils/utils.service";

import formulaireStoreModule from "@/store/formulaire";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "formulaire", module: formulaireStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormRadioGroup,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BForm,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    AppLogo,
    VBTooltip,
    BImg,

    //identification
    FormIdentification,
    // classification
    FormClassification,
    //tableaux
    FormTableau1,
    FormTableau2,
    FormTableau3,
    FormTableau4,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isSavingForm: false,
      infos: {},
      optionsOuiNon: ["Oui", "Non"],
      optionsNonVoyage: [
        "Pas intéressé",
        "Tarifs élevé de l'hébergement",
        "Tarifs élevé des transports en commun",
        "Insuffisance de transports en commun",
        "Autres",
      ],
      optionsMotifVoyage: [
        "Visite de parents d’amis",
        "Funérailles",
        "Vacances, loisirs et détente",
        "Éducation et formation",
        "Santé et soins médicaux",
        "Religion/pèlerinage",
        "Manifestation culturelle ou cultuelle",
        "Achat",
        "Transit",
        "Professionnel",
        "Voyage d’affaire individuel (par exemple, visite d’un fournisseur ou d’un client)",
        "Conférence/congrès/séminaire",
        "Autres",
      ],
      optionsTypeTransport: [
        "Voiture privée",
        "Voiture de location",
        "Voiture de service",
        "Taxi",
        "Transport en commun",
        "Avion",
        "Barque/pirogue",
        "Motocyclette",
        "Autres",
      ],
      optionsLieuResidence: [
        "Hôtel et assimilés",
        "Appartement/Hôtel",
        "Parents/amis",
        "Résidence secondaire",
        "Maison/appartement meublé",
        "Chambre d'hôte",
        "Autres",
      ],
      optionsActivites: [
        "Restaurant/bars/cafés/snack-bars",
        "Achats",
        "Concerts/festival de musique",
        "Monuments",
        "Manifestation culturelle/cultuelle",
        "Randonnée/trekking en forêt/montagne",
        "Visite des parcs nationaux",
        "Golf",
        "Chasse",
        "Manifestation sportive",
        "Théâtre",
        "Evènement familial (mariage, funérailles, baptême, première communion, etc.)",
        "Musées",
        "Zoo/parcs d’attraction",
        "Plage",
        "Formation/conférence/séminaire",
        "Visite de sociétés",
        "Autres",
      ],
      optionsSatisfaction: [
        "Très mécontent",
        "Mécontent",
        "Ni mécontent ni content",
        "Content",
        "Très Content",
      ],

      validInput: {
        paysResidence: "Bénin",
        nationalite: "Bénin",
        dateArriveeBenin: "2022-09-07",
        coVoyageursAdultes: 2,
        coVoyageursEnfants: 0,
        motifVoyage: "Visite de parentsvaluemis",
        activiteBenin: ["Excursions ", "Randonnée/trekking en forêt/montagne "],
        nombreNuitParType: [
          {
            type: "Hôtel/pension",
            nombreNuit: "15",
          },
          {
            type: "Parents/amis",
            nombreNuit: "4",
          },
          {
            type: "Auberge de jeunesse",
            nombreNuit: null,
          },
          {
            type: "Résidence secondaire",
            nombreNuit: null,
          },
          {
            type: "Maison/appartement loué",
            nombreNuit: null,
          },
          {
            type: "Chambre d’hôte",
            nombreNuit: null,
          },
          {
            type: "Autre type d`hébergement. Veuillez préciser",
            nombreNuit: null,
            hasManualInput: true,
            input: "",
          },
        ],
        moyenTransportArriveeBenin: "Avion",
        arriveeBeninParAvion: {
          type: "Vol commercial",
          compagnie: "Air France",
        },
        arriveeBeninParAutobus: {
          compagnie: null,
          residenceCompagnie: null,
        },
        moyenTransportBenin: "Taxi",
        depensesAvantArrivee: {
          type: {
            id: "Je n'ai rien payé avant mon arrivé",
            name: "Je n'ai rien payé avant mon arrivé",
            code: "aucun_paiement",
          },
          coutTotal: null,
          devise: null,
          options: [],
        },
        depensesDurantSejour: {
          coutTotal: 8755,
          options: [
            {
              label: "Transport international",
              devise: "",
              value: "4300",
            },
            {
              label: "Hébergement",
              devise: "",
              value: "4455",
            },
            {
              label: "Transport local (train, bus, taxi, etc.)",
              devise: "",
            },
            {
              label: "Location de voiture",
              devise: "",
            },
            {
              label: "Carburant automobile",
              devise: "",
            },
            {
              label: "Repas et boissons",
              devise: "",
            },
            {
              label: "Produits culturels",
              devise: "",
            },
            {
              label: "Sport et produit de loisirs",
              devise: "",
            },
            {
              label: "Bien et services liés à la santé",
              devise: "",
            },
            {
              label: "Souvenirs, cadeaux, etc",
              devise: "",
            },
            {
              label: "Textiles, vêtements, chaussures et produits en cuir",
              devise: "",
            },
            {
              label: "Taba",
              devise: "",
            },
            {
              label: "Objets de valeur",
              devise: "",
            },
            {
              label: "Autres",
              devise: "",
            },
          ],
        },
        satisfactionSejour: "Ni mécontent, ni content",
        frequenceVoyage: "Une fois par an",
        sexe: "Masculin",
        age: "33",
        auteur: "",
      },
      preview: null,
      isProcessingProvidedInput: true,
      sectionEditionModalRef: "edit-section-modal",
      sectionToEditComponent: null,
      sectionToEditData: null,
      editIconGreen: require("@/assets/images/icons/edit vert.png"),
      editIcon: require("@/assets/images/icons/edit.png"),
      currentUserId: localstorageService.getUserId(),
    };
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer;
      if (rendererType === "sidebar-left") {
        return "layout-content-renderer-left";
      }
      if (rendererType === "sidebar-left-detached") {
        return "layout-content-renderer-left-detached";
      }
      return "layout-content-renderer-default";
    },
  },
  mounted() {
    // return
    if (this.$route.params.data && this.$route.params.preview) {
      // console.log('this.$route.params.preview: ', this.$route.params.preview);
      console.log(
        "------ this.$route.params.data: ----- ",
        this.$route.params.data
      );

      const { data, preview } = this.$route.params;

      this.infos = { ...data };
      // this.infos = this.validInput

      if (preview) this.infos.editor = this.currentUserI;
      this.preview = this.$route.params.preview;
      // console.log(this.preview, this.infos)
      // console.log('ok')
    } else {
      // this.infos = this.validInput
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug },
      });
    }
  },
  methods: {
    ...mapActions("formulaire", {
      addFormulaire: "addFormulaire",
      updateFormulaire: "updateFormulaire",
    }),
    onEditionFormSuccess(evt) {
      this.infos = { ...this.infos, ...evt };
      this.hideSectionEditionModal();
      // console.log('onFormsuccess', evt)
    },
    // eslint-disable-next-line consistent-return
    getSectionToEditComponent(section) {
      switch (section) {
        case "identification":
          return "FormIdentification";
        case "classification":
          return "FormClassification";

        case "tableau1":
          return "FormTableau1";
        case "tableau2":
          return "FormTableau2";
        case "tableau3":
          return "FormTableau3";
        case "tableau4":
          return "FormTableau4";

        default:
          break;
      }
    },
    save() {
      const { currentUserId, etablissementDetails } =
        utilsService.currentUserUtils();
      this.isSavingForm = true;
      // console.log('this.infos: ', this.infos)
      const { isEditionOperation } = this.$route.params;
      const successMessage = !isEditionOperation
        ? "Formulaire enregistré avec succès"
        : "Opération réussie";
      const serviceMethode = isEditionOperation
        ? "updateFormulaire"
        : "addFormulaire";
      const { _id } = this.infos;
      delete this.infos._id;

      if (!this.infos.coVoyageursEnfants)
        delete this.infos["coVoyageursEnfants"];

      let data = {
        formType: "forms-tourisme-recepteurs",
        ...(isEditionOperation && { id: _id }),
        data: {
          ...this.infos,
        },
      };
      if (isEditionOperation) {
        data.data.editor = this.currentUserId;
      } else {
        data.data.auteur = currentUserId;
        data.data.auteurRole = userRoleId;
      }

      this[serviceMethode](data)
        .then((response) => {
          // console.log('============ response create form: ===========', response)
          // return
          if (response.status === 200) {
            if (
              this.$store.state.auth &&
              this.$store.state.auth.isAuthenticated
            ) {
              this.$router.push({
                name: "formulaires-list",
                params: { slug: this.$route.params.slug },
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            } else {
              this.$router.push({
                name: "home-public",
              });
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: successMessage,
                  icon: "CheckIcon",
                  variant: "success",
                  // text: 'Bienvenue !',
                },
              });
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Une erreur est suvenue, veuillez rééssayer",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
          this.isSavingForm = false;
        })
        .catch((error) => {
          this.isSavingForm = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                error.message || "Une erreur est suvenue, veuillez rééssayer",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    showSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].show();
    },
    hideSectionEditionModal() {
      this.$refs[this.sectionEditionModalRef].hide();
    },
    editSection(section) {
      this.sectionToEditComponent = this.getSectionToEditComponent(section);
      this.sectionToEditData = JSON.parse(JSON.stringify(this.infos));

      this.showSectionEditionModal();
    },
    edit() {
      this.$router.push({
        name: "formulaires-create",
        params: { slug: this.$route.params.slug, data: this.infos },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/base/themes/bordered-layout.scss";

.customborder1 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 15px 35px;
}
.customborder2 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  // padding: 0 35px 15px;
}

table {
  td,
  th,
  tr {
    text-align: left;
  }
}
thead {
  th {
    background-color: #0e6258 !important;
    color: white;
  }
}
</style>
